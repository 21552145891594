.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #850b9a;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.block{
  margin-bottom: 20px;
}

.block .item-list{
  margin-left: 20px;
  margin-top: 20px;
}

.block .item-list li{
  margin-bottom: 5px;
}

.find-block{
  padding:40px;
  background:#EEE;
  overflow: auto;
}
.find-block h1{
  margin-bottom: 30px;
  text-align:center;
}

.input-form{
  margin-bottom: 10px;
}
.formValue2{
  margin-left: 10px;
}

.message textarea{
  width:100%;
}
.fixed-block{
  position:sticky;
  top:30px;
}
.find-universal-company .big-input {
  padding:5px;
  font-size: 24px;
  width: 100%;
}

.find-universal-company .input-form label {
  width: 100%;
}


.blink {
  background-color: lightgreen;
  animation: blinker 0.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}